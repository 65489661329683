@import './breakPoints.scss';

@media only screen and (max-width: $sm) {
    .container-name {
        padding: 24px 0 16px 0;
        font-size: 16px;
        line-height: 20px;
        h2 {
            font-size: 16px;
            line-height: 25px;
        }
    }
    //.page-name {
    //    font-size: 27px;
    //    line-height: 33px;
    //    span {
    //        display: block;
    //    }
    //}
}
@import "../../../variables";

.signInButton {

  &--button-wrapper {
    background: #FFFFFF;
    cursor: pointer;
    max-width: 224px;
    min-width: 224px;
    /* Shadow/White/Strong */
    font-size: 15px;
    font-weight: 800;
    color: #1A81E0;
    box-shadow: 0px 6px 16px rgba(0, 115, 221, 0.1);
    border-radius: 27px 27px 27px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #ffff;
    height: 46px;
    transition: 0.3s;
    &:hover {
      border: 1px solid $mainColor;
    }
  }

  &--image-wrapper {
    background: #028DDEE5;
    border-radius: 22px 0 27px 0;
    height: 100%;
    min-width: 44px;
    max-width: 44px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 70px;
  }

  &--image {
    height: 25px;
    width: 20px;
  }
}
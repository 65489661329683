@import "../../variables.scss";


.auth-staging-page {
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: $mainColor;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-staging-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;

  &--logo {
    max-width: 200px;
    width: 100%;
  }

  &--title {
    color: $separateMainColor;
    margin: 10px 0;
  }

  &--input {
    &:first-child {
      margin-bottom: 10px !important;
    }
    margin-bottom: 20px !important;
  }
}
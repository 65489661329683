@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
    font-family: "Avenir";
    src : url("./fonts/Avenir-Heavy.ttf");
    font-weight: 900;
}
@font-face {
    font-family: "Avenir";
    src : url("./fonts/Avenir-Heavy.ttf");
    font-weight: 800;
}
@font-face {
    font-family: "Avenir";
    src : url("./fonts/Avenir-Medium.ttf");
    font-weight: 500;
}
@font-face {
    font-family: "Avenir";
    src : url("./fonts/Avenir-Roman.ttf");
    font-weight: 400;
}

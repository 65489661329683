$mainColor: #0073dd;
$separateMainColor: #FFFFFF;
$semiColor: #07c4e2;
$borderColor: white;
$backgroundColor: #f4f9ff;
$differenceColor: #CC7702;
$blackColor: #323945;
$activeBack: rgba(7, 196, 226, 0.07);
$shadow: 0 8px 18px 0 rgba(50, 57, 69, 0.05);
$buttonShadow: 0 8px 18px 0 rgba(7, 196, 226, 0.1);
$transition: 0.5s;
$avenirFont: "Avenir";
$paddingTop: 30px;

$mobile-max: 600px;
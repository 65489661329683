@import '../../../variables.scss';

#loader-container {
  color: $separateMainColor;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(229, 239, 252);

  @media (max-width: $mobile-max) {
    height: 75px;
  }
}

.loader-circle {
  margin: 50px auto;
  width: 60px;
  height: 60px;
  position: relative;
}

.loader-circle .loader-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.loader-circle .loader-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: $mainColor;
  /* background-color: #333; */
  border-radius: 100%;
  -webkit-animation: loader-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: loader-circleBounceDelay 1.2s infinite ease-in-out both;
}

.loader-circle .loader-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.loader-circle .loader-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.loader-circle .loader-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.loader-circle .loader-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.loader-circle .loader-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.loader-circle .loader-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.loader-circle .loader-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.loader-circle .loader-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.loader-circle .loader-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.loader-circle .loader-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.loader-circle .loader-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

.loader-circle .loader-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.loader-circle .loader-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.loader-circle .loader-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.loader-circle .loader-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.loader-circle .loader-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.loader-circle .loader-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.loader-circle .loader-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.loader-circle .loader-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.loader-circle .loader-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.loader-circle .loader-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.loader-circle .loader-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes loader-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes loader-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

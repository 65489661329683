@import "../../variables";

.sidebar-background {
    background: rgba(26, 129, 224, 1);
}

.flight-health-logo {
    width: 160px;
    height: 70px;
    position: absolute;
    bottom: 16px;
}

.sidebar-container {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: 100vh;
    width: 100%;
    padding: 46px 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
    a {
        color: white;
    }

    .info-card {
        width: 100%;
        padding: 14px 24px;
        background: #479CEA;
        border-radius: 7px;
        color: white;
        margin-bottom: 18px;
        display: flex;
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
        border: 1px solid #479CEA;

        transition: 300ms;
        &:hover {
            border: 1px solid #fff;
        }
        > img {
            width: 20px;
            height: 20px;
            fill: white;
            margin-right: 18px;
        }
    }

    .steps-wrapper {
        position: relative;
        top: -110px;
    }

    .steps-container {
        overflow: hidden;
        height: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        &:first-child {
            // border-top: 1px solid black;
        }
    }
}

.active-name {
    font-size: 15px;
    color: white;
    font-weight: 700;
}

.mobile-head-container {
    width: 100%;
    padding: 2px 15px 2px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-head-row {
    display: flex;
    align-items: center;
}

.company-name {
    color: white;
    padding-left: 5px;
}

.burger {
    margin-left: 15px;
}

.not-auth-logo {
    height: 100%;
    width: 100%;
    // z-index: -1;
    // position: absolute;
}

@import "./Adaptation.scss";

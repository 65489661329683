.line-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  hr {
    width: 100%;
    max-width: 155px;
    border: none;
    border-top: 1px solid #CECECE;
    color: #333;
    overflow: visible;
    text-align: center;
    margin: 21px 0 19px 0;
  }

  p {
    font-size: 17px;
    font-weight: 500;
    margin: 0 27px 0 27px;
  }
}
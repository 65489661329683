@import "../../variables.scss";

.error-or-success-page {
  display: flex;
  justify-content: center;
  width: 100%;
}

.animation-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.error-or-success-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;

  @media (max-width: $mobile-max) {
    margin-top: 40px;
  }

  &--contacts-wrapper {
    max-height: 260px;
    height: 100%;
    display: flex;
    background: #FFFFFF;
    border-radius: 14px;

    @media (max-width: $mobile-max) {
      max-height: 400px;
    }
  }

  &--link {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 23px;
    letter-spacing: 0.5px;
    text-decoration-line: underline;
    color: $mainColor;
    cursor: pointer;
    margin-top: 30px;
  }

  &--chat-wrapper {
    max-width: 550px;
    width: 100%;
    margin-top: 30px;
  }

  &--expiration-wrapper {
    max-width: 400px;
    width: 100%;
  }

  &--expiration-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    text-align: center;
  }

  &--expiration-subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    text-align: center;
    margin-bottom: 60px;
  }

  &--expiration-button-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  &--myAppointment-button-wrapper {
    max-width: 600px;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 30px;

    button:first-child {
      margin-right: 20px;
    }

    @media (max-width: $mobile-max) {
      flex-direction: column;

      button:first-child {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }

  &--hr-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 30px;
    margin-top: 30px;
    max-width: 550px;

    hr {
      width: 100%;
      max-width: 155px;
      border: none;
      height: 1px;
      background-color: #959595;
    }
  }

  &--success-img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    background: #EEF6E9;
    border-radius: 59px;
  }

  &--error-img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    background: #FFF4E6;
    border-radius: 59px;
  }

  &--title {
    font-style: normal;
    font-weight: 800;
    font-size: 21px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.25px;
    color: $blackColor;
    margin: 2px 0 8px 0;
  }

  &--subtitle {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: $blackColor;
  }

  &--description-wrapper {
    margin-top: 47px;
    background: #E6F1FC;
    border-radius: 12px;
    max-width: 446px;
    width: 100%;
    min-height: 108px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      max-width: 400px;
      font-style: normal;
      font-weight: 900;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      letter-spacing: 0.444444px;
      color: $blackColor;
    }

    a {
      color: $mainColor;
      font-style: normal;
      font-weight: 900;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      letter-spacing: 0.444444px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

}


.contacts-wrapper {
  &--phones {
    width: 80%;

    div {
      margin-left: 48px;

      @media (max-width: $mobile-max) {
        margin-left: 20px;
      }

      &:first-child {
        margin: 30px 0 30px 48px;

        @media (max-width: $mobile-max) {
          margin: 30px 0 30px 20px;
        }
      }

      &:last-child {
        margin: 30px 0 30px 48px;

        @media (max-width: $mobile-max) {
          margin: 30px 0 30px 20px;
        }
      }
    }

    &--title {
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 23px;
      letter-spacing: 0.5px;
      color: #323945;
    }

    &--phone {
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 23px;
      letter-spacing: 0.5px;
      color: $mainColor;
    }
  }

  &--schedule {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 26px;
      letter-spacing: 0.5px;
      color: #323945;
      margin-bottom: 10px;

    }
  }
}
@import '../../variables.scss';

.navbar-container {
    max-width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .navbar-languages {
        padding-right: 23px;
    }

    .navbar-user-container {
        display: flex;
        align-items: center;
        p {
            padding-right: 7px;
        }
    }

    .navbar-sign-off {
        height: 42px;
        display: flex;
        align-items: center;
        margin-left: 20px;
        background: #FFFFFF;
        box-shadow: 0 6px 16px rgba(0, 115, 221, 0.1);
        border-radius: 27px;
        padding: 8px 17px 8px 17px;
        cursor: pointer;
        transition: 300ms;
        border: 1px solid #fff;
        &:hover {
            border: 1px solid $mainColor;
        }

        p {
            cursor: pointer;
            font-size: 15px;
            text-align: center;
            margin-top: 0;
            color: $mainColor;
            font-weight: 800;
        }
    }
}
.name-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 100px;
    background-color: $mainColor;
    border: 1px solid #FFFFFF;
    filter: drop-shadow(0px 6px 16px rgba(0, 115, 221, 0.1));
    cursor: pointer;
}

.text-name-img {
    color: white;
    font-size: 16px;
    font-weight: 800;
}

.user-image {
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
    width: 44px;
    height: 44px;
    border: 1px solid #FFFFFF;
    filter: drop-shadow(0px 6px 16px rgba(0, 115, 221, 0.1));
}

.log-out-image {
    width: 23px;
    height: 23px;
    cursor: pointer;
}

@import '../../variables.scss';

.mobileSidebar {
    height: 50px;
    background: linear-gradient(213.69deg, #07C4E2 -89.02%, $mainColor 94.26%);
    position: fixed;
    z-index: 100;
    bottom: 0;
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;

    &.fullWidth {
        height: 80px;
    }

    &-image {
        height: 54px;
        width: 54px;
        border: 2px solid #FFFFFF;
        box-shadow: 0 6px 16px rgba(0, 115, 221, 0.1);
        border-radius: 50%;

        @media (max-width: $mobile-max) {
            height: 32px;
            width: 32px;
            border: 0.1px solid #FFFFFF;
        }
    }
    &-flightLogo {
        border-radius: 0;
        border: none;
    }
    &-title {
        font-weight: 500;
        font-size: 13px;
        line-height: 26px;
        color: #FFFFFF;
    }
    &-supportWrapper {
        position: relative;
        max-width: 92px;
    }
    &-info {
        margin-left: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &-blocks {
        display: flex;
        gap: 8px;
    }
    &-block {
        display: flex;
        align-items: center;
        background: #479CEA;
        border-radius: 7px;
        color: #ffff;
        padding: 6px 6px 6px 8px;
        &-icon {
            height: 12px;
            width: 12px;
            margin-right: 11px;
        }
    }
    &-popup {
        display: none;
        position: absolute;
        flex-direction: column;
        z-index: 9999;
        background: #479CEA;
        border-radius: 7px;
        max-width: 92px;
        width: 100%;
        margin-top: 1px;
    }

    &-popupItem {
        padding: 6px 6px 6px 8px;
        color: $separateMainColor;
        text-align: center;
    }

    @media(max-width: 959px) {
        position: initial;
        bottom: auto;
    }
}
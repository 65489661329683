.form-container {
    margin-top: 40px;
    width: 100%;
    max-width: 450px;
    h1 {
        font-weight: 700;
        font-size: 28px;
    }

    h4 {
        text-align: right;
        margin-top: 0;
        font-weight: 600;
        cursor: pointer;
    }

    h5 {
        font-size: 15px;
        text-align: center;
        font-weight: 600;

        span {
            color: $semiColor;
            cursor: pointer;
            margin-left: 5px;
        }
    }

    .check-container {
        // padding-bottom: 1em;
        a {
            color: $semiColor;
            font-weight: 500;
        }

        p {
            font-size: 13px;
        }

        .check-row {
            display: flex;
            align-items: center;
        }
    }

    .button-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
        margin-top: 30px;
        margin-bottom: 20px;
    }
}

.codes-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
        font-weight: 700;
    }
}
.social-media-login-buttons {
    flex-direction: row;
    height: 50px;
    margin-bottom: 15px;
    & > div {
        height: 100%;
        & > div {
            max-width: none !important;
        }
    }
    &.google {
        margin-top: 15px;
    }
    button.social-media-button {
        padding: 0;
    }

    .social-media-button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: 8px;
        box-shadow: $shadow;
        width: 100%;
        height: 50px;
        cursor: pointer;

        &.apple {
            background: #000;
            color: #fff;
        }

        > img {
            height: 14px;
            width: 14px;
            margin-right: 10px;
        }

        &:first-child {
            margin-right: 18px;
        }
    }
}

@import '../../../variables';

.styledButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #ffff;
  transition: 300ms;
  cursor: pointer;

  &--startAdornment {
    width: 25%;
    display: flex;
    justify-content: center;

    &.empty {
      width: 0;
    }
  }

  &--endAdornment {
    width: 25%;
    display: flex;
    justify-content: center;

    &.empty {
      width: 0;
    }
  }

  p {
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 23px;
    letter-spacing: 0.5px;
    text-align: center;
  }

  &:hover {
    border: 1px solid $mainColor;
    background: $separateMainColor;

    p {
      color: $mainColor;
    }
  }

  @media(max-width: 440px) {
    width: 150px;
  }
}

.Primary {
  background: $mainColor;
  color: #FFFFFF;

  p {
    color: #FFFFFF;
  }
}
.Secondary {
  background: #FFFFFF;
  color: $mainColor;

  p {
    color: $mainColor;
  }
}

.Error {
  background: #C64A4A;
  color: $separateMainColor;
  p {
    color: $separateMainColor;
  }

  &:hover {
    border: 1px solid #C64A4A;
    background: $separateMainColor;

    p {
      color: #C64A4A;
    }
  }
}

.FullWidth {
  width: 100%;
}

.Disable {
  background: #6f6f6f !important;
  border: 1px solid #ffff !important;

  p {
    color: #FFFFFF !important;
  }
}
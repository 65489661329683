@import "./variables.scss";
@import "./styles/buttons.scss";
@import "./styles/forms.scss";
@import "./styles/icons.scss";

// @import url('https://fonts.googleapis.com/css2?family=Domine:wght@400;700&family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');
* {
    list-style-type: none;
    outline: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Avenir";
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $blackColor;
}

h2 {
    font-size: 25px;
    line-height: 30px;
}

h4 {
    font-size: 18px;
    line-height: 25px;
}

h5 {
    font-size: 15px;
    font-weight: 400;
}

p {
    font-size: 12px;
    color: $blackColor;
}

a {
    color: $blackColor;
    text-decoration: none;
}

.page-name {
    text-align: center;
    font-size: 21px;
    line-height: 29px;
    span {
        font-weight: 400;
    }
}

.page-description {
    padding-top: 5px;
    line-height: 19px;
    text-align: center;
}

.content-container {
    width: 100%;
}

.container-name {
    padding: 48px 0 20px 0;
    font-size: 21px;
    line-height: 25px;
    h2 {
        font-size: 21px;
        line-height: 25px;
    }
}

.link {
    font-size: 15px;
    color: $semiColor;
    font-weight: 500;
    cursor: pointer;
}

.active-item {
    background-color: rgba(7, 196, 226, 0.07) !important;
    border: 1px solid $semiColor !important;
}

.row-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
}

#interactive.viewport {
    width: 400px;
    height: 300px;
}

#interactive.viewport canvas,
video {
    width: 400px;
    height: 300px;
    position: absolute;
    top: 0;
    left: 0;
}

#interactive.viewport canvas.drawingBuffer,
video.drawingBuffer {
    width: 400px;
    height: 300px;
}

.Toastify__toast {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 354px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 20px #00000029;
    padding: 33px 38px;
    border-radius: 17px;
    box-sizing: border-box;
    right: 50px;

    @media(max-width: 500px) {
        top: 20px;
        left: 5%;
    }
  }
  
  .Toastify__toast-body {
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-size: 19px;
    line-height: 29px;
    letter-spacing: 0px;
    color: #858997;
  }

  .yellow-label-label {
    color: #FF7639;
  }
  
  .green-label-label {
    color: #00CD98;
  }

@import "./styles/Adaptation.scss";

@import "../../styles/breakPoints.scss";

@media only screen and (max-width: $sm) {
    .auth-sidebar-background {
        background-image: none;
    }

    .not-auth-background {
        background-image: none;
    }

    .sidebar-container {
        height: auto;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        a {
            color: white;
        }

        .steps-container {
            padding-top: 12px;
            overflow: hidden;
            height: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: row;

            &:first-child {
                border-top: none;
            }
        }
    }

    .steps-wrapper {    
        padding: 15px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

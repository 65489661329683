@import '../../../variables';

.SignInButton {
  height: 42px;
  position: absolute;
  top: -50px;
  right: 18px;
  z-index: 100;
  display: flex;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0 6px 16px rgba(0, 115, 221, 0.1);
  border-radius: 27px;
  border: 1px solid #fff;
  padding: 8px 17px 8px 17px;
  cursor: pointer;
  transition: 300ms;
  font-size: 15px;
  font-weight: 800;
  color: $mainColor;


  &.right {
    right: 18px;
    left: auto;
  }

  &.left {
    left: 18px;
    right: auto;

    @media(max-width: $mobile-max) {
      left: 0;
    }
  }

  @media (max-width: $mobile-max) {
    position: absolute;
    top: -10px;
    right: 0px;
  }

  @media (min-width: $mobile-max) and (max-width: 900px) {
    top: -70px;
  }

  &:hover {
    border: 1px solid $mainColor;
  }
}
.person-icon {
    color: white;
    font-size: 13px !important;
}

.small-icon {
    color: $semiColor;
    font-size: 10px !important;
}

.cross-image {
    cursor: pointer;
}
